import React from "react";
import "./picWall.css"; // Import the CSS file for styling
import Image1 from "../heroSection/Daco_456015.png";
import Image2 from "../heroSection/dog-deal-with-it.gif";
import Image3 from "../heroSection/doge-doge-meme.gif";
import Image5 from "../heroSection/IMG_0025.png";
import Image6 from "../heroSection/doggle_3_1.png";
import Image7 from "../heroSection/heartMouth1.png";
import Image8 from "../heroSection/ligthening.png";
const images = [
  // Replace these with your image paths or URLs
  Image3,
  Image2,
  Image5,
  Image7,
  Image1,
  Image6,
  Image8,
  // Add as many images as you like
];

export const PictureWall = () => {
  return (
    <div className="picture-wall">
      {images.map((image, index) => (
        <div key={index} className="picture-item">
          <img src={image} alt={`Pic ${index + 1}`} />
        </div>
      ))}
    </div>
  );
};
