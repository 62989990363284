import styled from "styled-components";

export const FooterContainer = styled.div`
  background: white
  color: black;
  padding: 20px 50px;
  height: 200px;
`;

export const FooterWrapper = styled.div`
  max-width: 900px;
  height: "fit-content";
`;

export const FooterH2 = styled.h2`
  font-size: 2rem;
  color: white;
  font-weight: 500;
  margin-bottom: 30px;
`;

export const FooterLink = styled.a`
  font-size: 1.9rem;
  text-decoration: none;
  color: white;
  display: flex;
  transition: all 0.3s;

  &:hover {
    color: #b05ffd;
  }
`;
