import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavLinks2,
} from "./NavbarElements";

import "./../../App.css";
import { useHistory } from "react-router-dom";

export const Navbar = ({ toggle, backToHome = false }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };
  let history = useHistory();

  function backtoHome() {
    backToHome && history.push("/"); // Navigate to the About page
  }
  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // This smoothly scrolls to the top of the page
    });
  };

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to="/" onClick={scrollToTop}>
            <h1 className="logo-text">$ D o g g l e</h1>
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks2 href="https://raydium.io/swap/?inputCurrency=HBQ84Sk6ga8my6Cpk1h2XiSgC3go86yusFtj7tmzenrG&outputCurrency=sol&inputAmount=2500000&outputAmount=0.004056&fixed=in">
                Buy Doggle
              </NavLinks2>
            </NavItem>
            <NavItem>
              <NavLinks to="about" onClick={backtoHome}>
                How token
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="discover" onClick={backtoHome}>
                FAQ
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="services" onClick={backtoHome}>
                Future WOW
              </NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};
