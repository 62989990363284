import React, { useEffect, useState } from "react";
import { Navbar } from "./../navbar";
import { useHistory } from "react-router-dom";

//import "./../../App.css";
import "./jupiter.css";
import { Sidebar } from "../sidebar";

export const Jupitor = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  let history = useHistory();

  function handleClick() {
    history.push("/buy"); // Navigate to the About page
  }

  const toggle1 = () => {
    setIsOpen(!isOpen);
    history.push("/");
  };
  useEffect(() => {
    // Function to initialize Jupiter once it's available
    const initJupiter = () => {
      window.Jupiter.init({
        displayMode: "integrated",
        integratedTargetId: "integrated-terminal",
        strictTokenList: false,
        endpoint: "www",
        formProps: {
          fixedInputMint: true,
          fixedOutputMint: true,
          initialInputMint: "So11111111111111111111111111111111111111112",
          initialOutputMint: "HBQ84Sk6ga8my6Cpk1h2XiSgC3go86yusFtj7tmzenrG",
        },
      });
    };

    // Check if window.Jupiter is already available and initialize it
    let intervalId;
    if (window.Jupiter) {
      initJupiter();
    } else {
      // If not available, wait for it to load
      intervalId = setInterval(() => {
        if (window.Jupiter) {
          initJupiter();
          clearInterval(intervalId); // Stop checking once Jupiter is found
        }
      }, 100); // Check every 100ms
    }

    // Navigate if window.Jupiter doesn't become available after a timeout
    const timeoutId = setTimeout(() => {
      if (!window.Jupiter) {
        handleClick();
      }
    }, 10000); // Wait for 10 seconds before redirecting

    // Cleanup intervals and timeouts
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div>
      <Sidebar isOpen={isOpen} toggle={toggle1} />
      <Navbar toggle={toggle} backToHome={true} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "10px",
          overflow: "auto",
          position: "relative",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            marginTop: "100px",
            minWidth: "33%",
            fontSize: "15px",
            overflow: "auto",
            backgroundColor: "black",
            zIndex: 5,
          }}
        >
          <div id="integrated-terminal" />
        </div>
      </div>
    </div>
  );
};
