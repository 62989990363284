import React from "react";
import {
  FooterContainer,
  FooterH2,
  FooterLink,
  FooterWrapper,
} from "./FooterElements";
import "./../../App.css";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper className="container py-5" style={{ height: "200px" }}>
        <p>
          Disclaimer: Doggle is meant to be a fun meme token. Our goal is to
          make it useful, however with only 1% of total supply allocated to the
          team (so none of us can dump on you), the quality and outcome of the
          project (if any) is not guaranteed! so DYOR and don't YEET!
        </p>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
