import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.css";
import { InfoContainer, InfoWrapper, Subtitle, Title } from "./InfoElements";
import { ButtonBasic } from "../ButtonElements";
import { PieChart } from "react-minimal-pie-chart";
export const InfoSection = ({ id, title, subtitle, text, image, btnText }) => {
  return (
    <>
      <InfoContainer id={id} className="bg-black">
        <InfoWrapper>
          <div className="row expand-row gx-5">
            <div className="col-lg-6 col-sm-12 my-auto">
              <Subtitle>{subtitle}</Subtitle>
              <Title className="text-white mb-5">{title}</Title>
              <p className="text-white mb-4" style={{ fontSize: "20px" }}>
                {text}
              </p>
              <PieChart
                data={[
                  { title: "One", value: 1, color: "#E38627" },
                  { title: "Two", value: 99, color: "#C13C37" },
                ]}
                viewBoxSize={[130, 130]}
                label={({ dataEntry }) =>
                  dataEntry.value == 1
                    ? dataEntry.value + "% dev"
                    : dataEntry.value + "% lp"
                }
                labelStyle={{
                  fontSize: "5px",
                  fill: "white",
                }}
                radius={50}
                lineWidth={60}
              />
            </div>
            <div className="col-lg-6 col-sm-12 img-wrap">
              <img src={image} alt="" className="fit-img"></img>
            </div>
          </div>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
