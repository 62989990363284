export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }
}

export function pickRandomItems(array, numItems) {
  // Make a copy of the array to keep the original array unchanged
  const arrayCopy = [...array];
  shuffleArray(arrayCopy); // Shuffle the copy
  return arrayCopy.slice(0, numItems); // Return the first numItems elements
}
