import React, { useState, useEffect } from "react";
import {
  HeroContainer,
  HeroContent,
  HeroBtnWrapper,
  HeroImage,
} from "./HeroElements";
import "../../App.css";
import Image1 from "./Daco_456015.png";
import Image2 from "./giphy.gif";
import "./hero.css";
import { PictureWall } from "../picWall";
import Icon from "./logo-black.png";
import solIcon from "./logo_sol.png";
import birdIcon from "./eye_-anim-800.gif";
import dexIcon from "./dex.png";
import "./nav.css";

const HeroSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const walletAddress = "HBQ84Sk6ga8my6Cpk1h2XiSgC3go86yusFtj7tmzenrG";
  const [picWithRainbow, setPicWithRainbow] = useState(true);
  const bgColor = picWithRainbow ? "white" : "rgba(205, 205, 205, 0.2)";
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => setIsVisible(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  const copyTextToClipboard = async () => {
    try {
      // Ensure this method is called in direct response to user interaction
      await navigator.clipboard.writeText(walletAddress);
      setIsVisible(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
      alert("Failed to copy text. Please try again.");
    }
  };
  const togglePic = () => {
    setPicWithRainbow((prev) => !prev);
  };

  return (
    <HeroContainer style={{ background: bgColor }}>
      <HeroContent>
        <div>
          <p className="intro">
            What is Doggle? Doggle is the most 1337 doge of all doges wearing
            the most stylish shades staring at the brightest screens. Why? Cuz
            MUCH COOL DAM SEXY. So what is Doggle?{" "}
            <span
              class={picWithRainbow ? "flashing-text" : "not-flashing-text"}
            >
              ITS A DOG WEARING GOGGLES BRUH.
            </span>
          </p>
        </div>
        <div className="picAndButton">
          {" "}
          <HeroImage src={picWithRainbow ? Image2 : Image1}></HeroImage>
          {picWithRainbow ? (
            <button
              style={{
                height: "fit-content",
                marginLeft: "15px",
                fontSize: "20px",
              }}
              onClick={() => {
                togglePic();
              }}
            >
              Click here if you don't <br />
              have your doggle on
            </button>
          ) : (
            <button
              style={{
                height: "fit-content",
                marginLeft: "15px",
                fontSize: "20px",
              }}
              onClick={() => {
                togglePic();
              }}
            >
              Got my doggle, <br />
              turn rainbow on
            </button>
          )}
        </div>

        <PictureWall />
        <div
          className="token-address"
          style={{ cursor: "pointer" }}
          onClick={() => copyTextToClipboard()}
        >
          token address:{" "}
          <span style={{ border: "2px solid #000000" }}>{walletAddress} </span>
        </div>
        <div style={{ height: "15px", color: "green" }}>
          {isVisible ? "Copied!" : ""}
        </div>
        <div className="external-links" style={{ display: "flex" }}>
          <a
            href="https://solscan.io/token/HBQ84Sk6ga8my6Cpk1h2XiSgC3go86yusFtj7tmzenrG"
            style={{
              display: "flex",
              textDecoration: "none",
              color: "black",
              margin: "10px",
            }}
          >
            <img src={solIcon} className="icon" alt="Logo" id="nav-logo" />
            <span>Contract</span>
          </a>
          <a
            href="https://twitter.com/DoggleCoin"
            style={{
              display: "flex",
              textDecoration: "none",
              color: "black",
              margin: "10px",
            }}
          >
            <img src={Icon} className="icon" alt="Logo" id="nav-logo" />
            <span>twitter</span>
          </a>
          <a
            href="https://birdeye.so/token/HBQ84Sk6ga8my6Cpk1h2XiSgC3go86yusFtj7tmzenrG?chain=solana"
            style={{
              display: "flex",
              textDecoration: "none",
              color: "black",
              margin: "10px",
            }}
          >
            <img src={birdIcon} className="icon" alt="Logo" id="nav-logo" />
            <span>birdeye</span>
          </a>
          <a
            href="https://dexscreener.com/"
            style={{
              display: "flex",
              textDecoration: "none",
              color: "black",
              margin: "10px",
            }}
          >
            <img src={dexIcon} className="icon" alt="Logo" id="nav-logo" />
            <span>Dexscreener</span>
          </a>
        </div>

        <HeroBtnWrapper></HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
