import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarRoute2,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
} from "./SidebarElements";

export const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarRoute2
            href="https://raydium.io/swap/?inputCurrency=HBQ84Sk6ga8my6Cpk1h2XiSgC3go86yusFtj7tmzenrG&outputCurrency=sol&inputAmount=2500000&outputAmount=0.004056&fixed=in"
            onClick={toggle}
          >
            Buy Doggle
          </SidebarRoute2>
          <SidebarLink to="about" onClick={toggle}>
            How token
          </SidebarLink>
          <SidebarLink to="dsicover" onClick={toggle}>
            FAQ
          </SidebarLink>
          <SidebarLink to="services" onClick={toggle}>
            Future WOW
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};
