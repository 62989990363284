import React from "react";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";
import Image1 from "../heroSection/Daco_456015.png";
import Image2 from "../heroSection/dog-deal-with-it.gif";
import Image9 from "../heroSection/doge-doge-meme.gif";
import Image5 from "../heroSection/IMG_0025.png";
import Image6 from "../heroSection/doggle_3_1.png";
import Image7 from "../heroSection/heartMouth1.png";
import Image8 from "../heroSection/ligthening.png";
import { pickRandomItems } from "../randomItemsUtil";
const Services = () => {
  const images = [
    Image5,
    Image7,
    Image1,
    Image6,
    Image8,
    Image9,
    // Add as many images as you like
  ];
  const randomItems = pickRandomItems(images, 3);
  return (
    <ServicesContainer id="services">
      <ServicesH1>RoadMap</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={randomItems[0]} />
          <ServicesH2>doggles everywhere</ServicesH2>
          <ServicesP>
            create and post a picture of $doggle and tag @dogglecoin on X for a
            chance to win $1000 USD On April 20th 2024!
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={randomItems[1]} />
          <ServicesH2>doggle generator</ServicesH2>
          <ServicesP>
            Calling all artists! Join us in crafting unique NFTs of Doggle
            wearing glasses. we'll be making an AI powered doggle generator tool
            so all dogs can have shades!
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={randomItems[2]} />
          <ServicesH2>Make Doggle useful!</ServicesH2>
          <ServicesP>
            We hope to develop an awesome multiplayer MEME Game that will use
            $DOGGLE as the currency to play! With a % of $DOGGLE burned each
            game, to make the coin more valuable while winners takes the other
            players coin. YES, we have it planned! but Much Secret, Very HUSH!
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
