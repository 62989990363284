import React, { useState } from "react";
import HeroSection from "../components/heroSection";
import InfoSection from "../components/infoSection";
import { Navbar } from "./../components/navbar";
import { Sidebar } from "./../components/sidebar";
import Image3 from "../images/Daco_456015.png";

import InfoSectionLight from "../components/infoSectionLight";
import Services from "../components/services";
import Footer from "../components/footer";

import Image1 from "../components/heroSection/Daco_456015.png";
import Image2 from "../components/heroSection/dog-deal-with-it.gif";
import Image9 from "../components/heroSection/doge-doge-meme.gif";
import Image5 from "../components/heroSection/IMG_0025.png";
import Image6 from "../components/heroSection/doggle_3_1.png";
import Image7 from "../components/heroSection/heartMouth1.png";
import Image8 from "../components/heroSection/ligthening.png";
import { pickRandomItems } from "../components/randomItemsUtil";

export const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const images = [
    // Replace these with your image paths or URLs
    Image3,
    Image5,
    Image7,
    Image1,
    Image6,
    Image8,
    Image9,
    // Add as many images as you like
  ];
  const randomItems = pickRandomItems(images, 3);
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <HeroSection id="home" />
      <InfoSection
        image={randomItems[0]}
        id="about"
        subtitle="100% LP Burn"
        title="Token Distribution"
        text="100% LP Burn, No Presale, No Airdrop"
        btnText="Start today"
      />
      <InfoSectionLight
        image={Image2}
        id="discover"
        //subtitle="Morbi iaculis enim"
        title="Question: Why call it goggle when it clearly is sunglasses"
        text="Answer: DEAL WITH IT"
        btnText="Explore"
      />

      <Services />
      <Footer style={{ heigth: "fit-content", padding: "0px" }} />
    </>
  );
};
